import React from 'react'
import ContentBlock from 'components/contentBlock'
import { trFunction } from 'utils/functions'
import Layout from 'components/layout'
import { graphql } from 'gatsby'

const Page = ({ data }) => {
  const locale = data.site.siteMetadata.locale
  const translations = data.translations
  const tr = (code) => trFunction(code, translations, locale)
  const catalogs = data.catalogs
  const catalogBlocks = catalogs.edges.map((catalog) => {
    return {
      title: catalog.node.title,
      subtitle: catalog.node.subtitle,
      template: 'full-images',
      textPosition: 'bottom left',
      disableOverlay: true,
      isDarkText: true,
      isDarkTransparentNoChange: true,
      imagesFull: [catalog.node.imageMain],
      imagesFullMobile: [catalog.node.imageMainMobile],
      catalog: catalog.node.catalog,
      addClass: 'catalog',
    }
  })

  return (
    <Layout>
      <div id="content">
        {catalogBlocks.map((block, i) => (
          <ContentBlock key={i} order={i} block={block} data={data} />
        ))}
      </div>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    catalogs: allContentfulCatalogs(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          order
          title
          subtitle
          imageMain {
            ...GatsbyImage
          }
          imageMainMobile {
            ...GatsbyImage
          }
          catalog {
            file {
              url
            }
          }
        }
      }
    }
  }
`
